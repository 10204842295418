<template>
	<div class="case">
		<div class="banner">
			<headers></headers>
			<img src="../../assets/image/jythjbanner.png" alt="">
		</div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>产品介绍</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="news_case">
			<div class="titles">
				<div class="dv1">推荐产品</div>
				<div class="dv2">Recommended products</div>
			</div>
			<div class="box">
				<div class="left">
					<img src="../../assets/image/product1.png" alt="">
				</div>
				<div class="right">
					<div class="tit">幼儿园实景教学系统</div>
					<!-- <div class="work">XXXXXXXX学校/机构</div> -->
					<div class="content">幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统幼儿园实景教学系统........</div>
					<router-link to="/product_details" class="more">查看更多</router-link>
				</div>
			</div>
		</div>
		<div class="case_list">
			<div class="titles">
				<div class="dv1">产品介绍</div>
				<div class="dv2">Product introduction</div>
			</div>
			<div class="box">
				<div class="navigation">
					<span>项目类型：</span>
					 <el-select @change="listclick" size="mini" style="width: 70px;"  v-model="company" placeholder="请选择">
					    <el-option
					      v-for="(item,index) in case_list"
					      :key="index"
					      :label="item"
					      :value="item">
					    </el-option>
					  </el-select>
					<span class="class">创建时间：</span>
					<el-select popper-class="dfg" @change="listclick2" size="mini" style="width: 60px;"  v-model="company2" placeholder="请选择">
					   <el-option
					     v-for="(item,index) in case_list2"
					     :key="index"
					     :label="item"
					     :value="item">
					   </el-option>
					 </el-select>
					<!-- <el-dropdown trigger="click" @command="listclick2">
						<span class="el-dropdown-link">
							{{company2}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in case_list2" :key="index" :command="item">{{ item }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
				</div>
				<transition name="el-zoom-in-top">
				<div class="list" v-show="dhshow">
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product2.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product3.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product4.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product5.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product2.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
					<div class="list_body">
						<div class="left">
							<img src="../../assets/image/product3.png" alt="">
						</div>
						<div class="right">
							<div class="tit">技能展示系统</div>
							<div class="content">校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍校园文化艺术节技能展示系统介绍</div>
							<router-link class="more" to="/product_details">点击查看</router-link>
						</div>
					</div>
				</div>
				</transition>
				<div class="paging" v-show="dhshow">
					<el-pagination background layout="prev, pager, next,total" :total="40" :page-size="8" @current-change="currentchange">
					</el-pagination>
				</div>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import headers from '../../components/header.vue'
	import Foot from '../../components/Foot.vue'
	export default {
		components: {
			headers,
			Foot
		},
		data() {
			return {
				case_list: ['选项一', '选项二', '选项三', '选项四选项四'],
				company: '全部',
				case_list2: ['2016', '2017', '2018', '2019'],
				company2: '全部',
				dhshow:false,
				screenWidth:''
			}
		},
		mounted() {
			let that= this
			let l = document.documentElement.clientWidth
			that.screenWidth = l
			window.addEventListener('scroll', that.handleScrollx, true)
			 window.onresize = () => {
			      return (() => {
			        window.screenWidth = document.body.clientWidth
			        that.screenWidth = window.screenWidth
			        // console.log(that.screenWidth)
			      })()
			    }
		},
		methods: {
			listclick(command) {
				console.log(command)
			},
			listclick2(command) {
				console.log(command)
			},
			currentchange(e) {
				console.log(e)
			},
			handleScrollx() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				    if(this.screenWidth>900){
						if (top >= 900) {
							this.dhshow = true
						} else {
							this.dhshow = false
						}
					}
					if(this.screenWidth<900){
						if (top >= 500) {
							this.dhshow = true
						} else {
							this.dhshow = false
						}
					}
					
					//console.log('滚动高度', window.pageYOffset)
					// console.log('滚动高度', top)
					// console.log(this.screenWidth)
					// console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
				}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/less/product_index.scss";
</style>
<style>
	.case_list .el-input--mini .el-input__inner{
		    border: none;
			padding-left: 0;
	}
</style>
